import { render, staticRenderFns } from "./sendSMS.vue?vue&type=template&id=838a9146&scoped=true&"
import script from "./sendSMS.vue?vue&type=script&lang=js&"
export * from "./sendSMS.vue?vue&type=script&lang=js&"
import style0 from "./sendSMS.vue?vue&type=style&index=0&id=838a9146&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "838a9146",
  null
  
)

export default component.exports