<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 手机短信 </template>
      <template #input>
        <!-- <a-input placeholder="请输入关键字" />
        <a-button
          type="primary"
          class="btn"
        >搜索</a-button> -->
        <a-button class="all_boder_btn" @click="toDetail(1, null)"
          >新建</a-button
        >
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :rowKey="item=>item.customMessageId"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      @change="onPage"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }"
    >
      <template slot="pushStatus" slot-scope="item, row">
        {{
          row.pushStatus == 0
            ? "未发送"
            : row.pushStatus == 1
            ? "已发送"
            : "取消发送"
        }}
      </template>

      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>

      <template
        slot="operation"
        slot-scope="item, row"
      >
        <span class="blueText" v-if="row.pushStatus == 0">
          <span @click="toDetail(3, row.customMessageId)">编辑</span>
          |
          <span @click="cancelSending(row.customMessageId)">取消发送</span>
        </span>
        <span v-else>-</span>
      </template>
    </a-table>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "短信文案",
    align: "center",
    dataIndex: "content",
  },
  {
    title: "发送人数",
    align: "center",
    dataIndex: "pushNum",
  },
  {
    title: "发送时间",
    align: "center",
    dataIndex: "pushTime",
  },
  {
    title: "推送状态",
    align: "center",
    dataIndex: "pushStatus",
    scopedSlots: {
      customRender: "pushStatus",
    },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {
    HeaderBox,
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      name: "",
      columns,
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      // 传输数据
      operations: "",
      id: "",
      loading: false
    };
  },
  // 事件处理器
  methods: {
    toDetail(operation, id) {
      this.$router.push(
        "/admin/MessageManage/newSMS?" +
          "userType=2" +
          "&operation=" +
          operation +
          "&id=" +
          id
      );
    },
    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/custom/short/message/manage",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          keyWord: this.name,
        },
      }).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.loading = false
      });
    },

    // 操作对象
    cancelSending(e) {
      let _this = this;
      this.$confirm({
        title: "确定取消发送吗?",
        okText: "确认",
        onOk() {
          _this.$ajax({
            url: "/hxclass-management/custom/short/message/manage/cancel/" + e,
            method: "PUT",
            params: {
              // customId: e.customMsgId,
            },
          }).then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success("操作成功");
              _this.getdataList();
            } else {
              _this.$message.error(res.message);
            }
          });
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.getdataList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less"  scoped>
.all_main_wrap {
  min-height: 100%;

  .btn {
    margin: 0 24px 14px 0;
  }
}
</style>
